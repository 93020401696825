/* eslint-env browser */
import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import UncontrolledRadioInput from "../../shared/react/UncontrolledRadioInput.jsx";

const ordinalNames = ["Primary", "Secondary", "Tertiary"];

export default async function handleAddressSubmit(confirm, data, getValues, setValue) {
  let addresses = [
    {name: "patientAddress", label: "Patient Address", options: [data.patientAddress]},
    {name: "physicianAddress", label: "Ordering Physician Address", options: [data.physicianAddress]},
    ...data.insurance.map((i) => ({
      name: "subscriberAddress",
      priority: i.priority,
      label: `${ordinalNames[i.priority - 1]} Insurance Subscriber Address`,
      options: [i.subscriberAddress],
    })),
    ...data.insurance.map((i) => ({
      name: "claimsAddress",
      priority: i.priority,
      label: `${ordinalNames[i.priority - 1]} Insurance Claims Address`,
      options: [i.claimsAddress],
    })),
  ].filter((addressObject) => !!addressObject.options[0]);

  if (addresses.length > 0) {
    try {
      // Get the verified addresses
      await Promise.all(
        addresses.map(async (addressObject) => {
          try {
            const {
              data: {formattedAddress},
            } = await axios({
              method: "post",
              url: "/facilities/verifyAddress",
              data: {street: addressObject.options[0]},
            });

            if (addressObject.options[0] !== formattedAddress) {
              addressObject.options.push(formattedAddress);
            }
          } catch (err) {
            // if address returns 404, do nothing
          }
        })
      );

      addresses = addresses.filter((address) => address.options.length > 1);

      if (addresses.length > 0) {
        // Let the user choose which address to use and then make the change
        await confirm({
          title: "Confirm Addresses",
          content: <AddressConfirmation addresses={addresses} setValue={setValue} />,
          confirmationText: "Ok",
        });
      }

      addresses.forEach((address) => {
        if (address.priority !== undefined) {
          data.insurance[address.priority - 1][address.name] = getValues(
            `insurance[${address.priority - 1}].${address.name}`
          );
        } else {
          data[address.name] = getValues(address.name);
        }
      });
    } catch (err) {
      // revert the values to the user entry value
      addresses.forEach((a) => {
        const name = a.priority !== undefined ? `insurance[${a.priority - 1}].${a.name}` : a.name;
        setValue(name, a.options[0]);
      });

      throw err;
    }
  }

  return data;
}

function AddressConfirmation({addresses, setValue}) {
  return (
    <Grid container>
      <Typography>{`We found ${addresses.length > 1 ? "" : "a "}similar verified address${
        addresses.length > 1 ? "es" : ""
      }. Please select the address${addresses.length > 1 ? "es" : ""} you would like to use.`}</Typography>
      {addresses.map((a, index) => {
        const name = a.priority !== undefined ? `insurance[${a.priority - 1}].${a.name}` : a.name;

        return (
          <Grid item xs={12} key={name} sx={{mt: 3}}>
            <UncontrolledRadioInput
              row={false}
              id={`${a.name}ConfirmationSelect${a.priority ? `-${a.priority}` : ""}`}
              name={name}
              value={a.options[1]}
              setValue={setValue}
              options={a.options}
              tooltips={["Keep Original Address", "Use Suggested Verified Address"]}
            >
              <FormLabel>{a.label}</FormLabel>
            </UncontrolledRadioInput>
          </Grid>
        );
      })}
    </Grid>
  );
}
AddressConfirmation.propTypes = {
  addresses: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      priority: PropTypes.number,
      label: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
  setValue: PropTypes.func.isRequired,
};
